<template lang="pug">
.form-group
    label(:for="id") {{ label }}
    a-form-item(
        :validate-status="errors.length ? 'error' : ''",
        :hasFeedback="!!errors.length"
    )
        template(v-slot:help="")
            span(v-for="error in errors") {{ error }}
        a-select(
            v-model:value="value",
            :mode="multiple ? 'multiple' : undefined"
        )
            a-select-option(
                v-for="option in selection",
                :value="option.value",
                :key="option.value",
                :selected="option.selected"
            ) {{ option.text }}
    select.wtform-select-field-hidden-select(
        :id="id",
        :name="name",
        :multiple="multiple"
    )
        template(v-if="multiple")
            option(v-for="option in value", :value="option", selected)
        option(v-else, :value="value", selected)
</template>
<script lang="ts">
import { Select } from "ant-design-vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "WtformSelectField",
    components: { "a-select": Select, "a-select-option": Select.Option },
    props: {
        choices: {
            type: Array,
        },
        errors: {
            type: Array,
            required: true,
        },
        format: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        multiple: {
            type: Boolean,
        },
        label: {
            type: String,
        },
        name: {
            type: String,
        },
        id: {
            type: String,
        },
    },
    data() {
        const selection: {
            value: string;
            text: string;
            selected: boolean;
        }[] = [];
        const value: string[] = [];

        return {
            value,
            selection,
        };
    },
    created() {
        this.selection = this.choices.map((e) => ({
            value: e[0],
            text: e[1],
            selected: e[2],
        }));
        this.value = this.selection
            .filter((e) => e.selected)
            .map((e) => e.value);
    },
});
</script>
<style lang="sass" scoped>
.wtform-select-field-hidden-select, .wtform-select-field-hidden-select:focus
    // Copied from .select2-offscreen
    clip: rect(0 0 0 0) !important
    width: 1px !important
    height: 1px !important
    border: 0 !important
    margin: 0 !important
    padding: 0 !important
    overflow: hidden !important
    position: absolute !important
    outline: 0 !important
    left: 0px !important
    top: 0px !important
</style>
