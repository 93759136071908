import "bootstrap";
import "@fontsource/source-sans-pro/300.css";
import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/700.css";
import { createApp } from "vue";

/*
 * If you want to start using dependencies, please do it the following way:
 * https://bootstrap-vue.js.org/docs/#tree-shaking-with-module-bundlers
 * ~ Wilco - 09-11-2019
 */
import * as Sentry from "@sentry/vue";
import { i18n } from "./translations";
import "./assets/scss/ant.less";
import { router } from "./router";
import { errorHandler } from "./utils/errors";
import Flask from "./utils/flask";

/*
 * These are the "misc" imports, they aren't packed together.
 */
import App from "./components/App.vue";
import FooterCarousel from "./components/footer_carousel.vue";
import { Breadcrumb, Form, Pagination } from "ant-design-vue";
import WtformDatePicker from "./components/form/wtform_date_picker.vue";
import WtformSelectField from "./components/form/wtform_select_field.vue";
import Vue3TouchEvents from "vue3-touch-events";
import infiniteScroll from "./components/photos/infiniteScroll";

declare let SentryConfig: {
    dsn: string;
    release: string;
    environment: string;
};

// Use a temporary component to initialize the app.
// This is needed, because we use runtime rendering of the app.
// Flask places the <App /> component on the pages where it is needed. See vue.htm.
const app = createApp({});

Sentry.init({
    app,
    dsn: SentryConfig.dsn,
    release: SentryConfig.release,
    environment: SentryConfig.environment,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 0.5,
    tracePropagationTargets: [
        /^https:\/\/develop\.svia\.nl/,
        /^https:\/\/svia\.nl/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
app.config.errorHandler = errorHandler;

app.use(router);
app.use(i18n);

app.component("App", App);
app.component("FooterCarousel", FooterCarousel);
app.component("WtformDatePicker", WtformDatePicker);
app.component("WtformSelectField", WtformSelectField);
app.component("ABreadcrumb", Breadcrumb);
app.component("ABreadcrumbItem", Breadcrumb.Item);
app.component("APagination", Pagination);
app.component("AForm", Form);

app.use(Vue3TouchEvents);
app.use(infiniteScroll);

app.config.globalProperties.setLocationHref = (
    page: number,
    endpoint: string,
    kwargs: Record<string, unknown>
) => {
    window.location.href = Flask.url_for(endpoint, {
        page: page,
        ...kwargs,
    });
};

app.mount("#container-main");
